"use client";

import type { ButtonProps } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Play from "@untitled-ui/icons-react/build/esm/Play";
import XClose from "@untitled-ui/icons-react/build/esm/XClose";
import type React from "react";
import { useState } from "react";
import { DynamicPlacementTooltip } from "../tooltips/dynamic-tooltip";

export type TutorialButtonProps = ButtonProps & {
  videoUrl: string;
  tooltipText?: string;
  link?: boolean;
  modalTitle?: string;
};

export const TutorialButton = ({ videoUrl, modalTitle, tooltipText, ...props }: TutorialButtonProps) => {
  const [open, setOpen] = useState(false);

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (props?.link) {
      window.open(videoUrl, "_blank");
      return;
    }
    setOpen(true);
    props?.onClick?.(e);
  };
  return (
    <>
      <DynamicPlacementTooltip title={tooltipText}>
        <Button variant="outlined" onClick={handleOpen} startIcon={<Play style={{ width: 15 }} />} {...props}>
          Watch tutorial
        </Button>
      </DynamicPlacementTooltip>
      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <DialogTitle
          sx={{
            fontSize: "1.0rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {modalTitle ?? "How to use this feature"}
          <IconButton onClick={handleClose}>
            <XClose />
          </IconButton>
        </DialogTitle>
        {/* Tutorial video */}
        <iframe
          width="560"
          height="315"
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </Dialog>
    </>
  );
};
