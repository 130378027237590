import { useOrganization, useUser } from "@clerk/nextjs";
import {
  Box,
  Button,
  Card,
  LinearProgress,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { GettingStartedSteps } from "@prisma/client";
import {
  Building01,
  CheckCircle,
  Circle,
  HelpCircle,
  Mail01,
  PhoneCall02,
  Signal02,
  User02,
  Zap,
} from "@untitled-ui/icons-react";
import { useRouter } from "next-nprogress-bar";
import posthog from "posthog-js";
import { useCallback, useMemo } from "react";
import { FaYoutube } from "react-icons/fa";
import { PostHogEvents } from "~/services/third-party/posthog";
import { api } from "~/trpc/react";

export const BOOK_A_CALL_LINK = "https://meetings-eu1.hubspot.com/max-mitcham/onboarding-call";

const HELP_LINK = "https://help.trigify.io/en";

const generateSteps = ({
  stepsCompleted,
  onWatchHowToUseTrigify,
  onBookOnboardingCall,
  onAccessHelpCenter,
  onProspectLead,
  onSyncSetup,
  onConnectIntegration,
  organization,
}: {
  onWatchHowToUseTrigify: () => void;
  onBookOnboardingCall: () => void;
  onAccessHelpCenter: () => void;
  onProspectLead: () => void;
  onSyncSetup: () => void;
  onConnectIntegration: () => void;
  organization?: { id: string } | null;
  stepsCompleted: GettingStartedSteps[];
}) =>
  [
    {
      index: 0,
      title: "Added account and company info",
      StartIcon: Building01,
      EndIcon:
        stepsCompleted.includes(GettingStartedSteps.CREATED_PROFILE_AND_COMPANY) || organization?.id
          ? CheckCircle
          : Circle,
      onClick: () => null,
    },
    {
      index: 1,
      title: "Watch how to use Trigify",
      StartIcon: FaYoutube,
      EndIcon: stepsCompleted.includes(GettingStartedSteps.WATCHED_HOW_TO_USE_TRIGIFY) ? CheckCircle : Circle,
      onClick: onWatchHowToUseTrigify,
    },
    {
      index: 2,
      title: "Book an onboarding call",
      StartIcon: PhoneCall02,
      EndIcon: stepsCompleted.includes(GettingStartedSteps.BOOKED_ONBOARDING_CALL) ? CheckCircle : Circle,
      onClick: onBookOnboardingCall,
    },
    {
      index: 3,
      title: "Access the help center",
      StartIcon: HelpCircle,
      EndIcon: stepsCompleted.includes(GettingStartedSteps.ACCESSED_HELP_CENTER) ? CheckCircle : Circle,
      onClick: onAccessHelpCenter,
    },
    {
      index: 4,
      title: "Prospect your first lead via signals",
      StartIcon: User02,
      EndIcon: stepsCompleted.includes(GettingStartedSteps.PROSPECT_LEAD) ? CheckCircle : Circle,
      onClick: onProspectLead,
    },
    {
      index: 5,
      title: "Sync your first LinkedIn account",
      StartIcon: Signal02,
      EndIcon: stepsCompleted.includes(GettingStartedSteps.LINKEDIN_SYNC) ? CheckCircle : Circle,
      onClick: onSyncSetup,
    },
    {
      index: 6,
      title: "Connect your first enablement integration",
      StartIcon: Zap,
      EndIcon: stepsCompleted.includes(GettingStartedSteps.INTEGRATION_CONNECTED) ? CheckCircle : Circle,
      onClick: onConnectIntegration,
    },
  ] as const;

export const GettingStartedWidget = () => {
  const { mutate: captureEvent } = api.analytics.captureEvent.useMutation();
  const { data: events } = api.analytics.getUserGettingStartedEvents.useQuery();
  const { organization } = useOrganization();
  const { user } = useUser();

  const onWatchHowToUseTrigify = useCallback(() => {
    posthog.capture(PostHogEvents.WATCHED_HOW_TO_USE_TRIGIFY, {
      user: user,
    });
    void captureEvent({
      event: GettingStartedSteps.WATCHED_HOW_TO_USE_TRIGIFY,
    });
    window.open("https://www.youtube.com/@trigifyautomation", "_blank");
  }, [user, captureEvent]);

  const onBookOnboardingCall = useCallback(() => {
    posthog.capture(PostHogEvents.BOOKED_ONBOARDING_CALL, {
      user: user,
    });
    void captureEvent({
      event: GettingStartedSteps.BOOKED_ONBOARDING_CALL,
    });
    window.open(BOOK_A_CALL_LINK, "_blank");
  }, [user, captureEvent]);

  const onAccessHelpCenter = useCallback(() => {
    posthog.capture(PostHogEvents.ACCESSED_HELP_CENTER, {
      user: user,
    });
    void captureEvent({
      event: GettingStartedSteps.ACCESSED_HELP_CENTER,
    });
    window.open(HELP_LINK, "_blank");
  }, [user, captureEvent]);

  const handleSlackInvite = () => {
    window.open(
      `https://join.slack.com/t/trigifyioworkspace/shared_invite/zt-2dqvg0tyt-71m0dvX5sUAGXm~HuMsT~A`,
      "_blank",
    );
  };

  const router = useRouter();

  const onProspectLead = useCallback(() => {
    // navigate to /lists and open the prospect list
    router.push(`/lists/${organization?.id}?modalState=open`);
  }, [router, organization]);

  const onSyncSetup = useCallback(() => {
    // navigate to /integrations and open the linkedin integration
    router.push("/social/setup");
  }, [router]);

  const onConnectIntegration = useCallback(() => {
    // navigate to /integrations and open the integrations page
    router.push(`/connect/${organization?.id}/accounts`);
  }, [router, organization]);

  const stepsCompleted = useMemo(() => {
    const e = events?.map((event) => event) ?? [];
    const uniqueEvents = Array.from(new Set(e));
    if (organization?.id) {
      uniqueEvents.push(GettingStartedSteps.CREATED_PROFILE_AND_COMPANY);
    }
    return uniqueEvents;
  }, [events, organization]);

  const steps = useMemo(
    () =>
      generateSteps({
        stepsCompleted: stepsCompleted ?? [],
        onWatchHowToUseTrigify: onWatchHowToUseTrigify,
        onBookOnboardingCall: onBookOnboardingCall,
        onAccessHelpCenter: onAccessHelpCenter,
        onProspectLead,
        onSyncSetup,
        onConnectIntegration,
        organization,
      }),
    [
      onWatchHowToUseTrigify,
      onBookOnboardingCall,
      onAccessHelpCenter,
      stepsCompleted,
      onProspectLead,
      onSyncSetup,
      onConnectIntegration,
      organization,
    ],
  );

  const progress = Math.round(((stepsCompleted?.length ?? 0) / steps.length) * 100);

  const theme = useTheme();

  return (
    <Card variant="outlined" elevation={0} sx={{ my: 1, borderRadius: 3, p: 2 }}>
      <Box
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <LinearProgress
          // add line cap
          sx={{
            strokeLinecap: "round",
            borderRadius: 5,
            mb: 1,
          }}
          variant="determinate"
          value={((stepsCompleted?.length ?? 0) / Math.round(steps.length)) * 100}
        />
        <Typography variant="caption" sx={{ textAlign: "right" }}>
          {progress} % completed
        </Typography>
      </Box>
      {steps.map(({ StartIcon, EndIcon, title, onClick }) => (
        <ListItemButton onClick={onClick} key={crypto.randomUUID()}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              mr: 1,
            }}
          >
            <ListItemIcon>
              <StartIcon />
            </ListItemIcon>
            <ListItemText primary={title} />
          </Box>
          <EndIcon width={18} color={theme.palette.primary.main} />
        </ListItemButton>
      ))}
      <Box
        sx={{
          p: 2,
          display: "flex",
          gap: 2,
          alignItems: "center",
          flexDirection: "row",
          mt: 2,
        }}
      >
        <img
          src="/assets/slack.svg"
          alt="slack"
          style={{
            width: 30,
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h4" sx={{ fontSize: 14 }}>
            Get support from our team and community
          </Typography>
          <Typography variant="caption">
            Need help navigating the platform? Ask our Trigify team in our Slack community
          </Typography>
          <Button
            sx={{ mt: 1, maxWidth: 200 }}
            variant="outlined"
            onClick={handleSlackInvite}
            startIcon={<Mail01 width={16} />}
          >
            Accept invitation
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
