"use client";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import type { TutorialButtonProps } from "../buttons/tutorial-button";
import { TutorialButton } from "../buttons/tutorial-button";

type TutorialCardProps = {
  title: string;
  imageUrl: string;
  description: string;
  buttonProps: TutorialButtonProps;
};
export const TutorialCard = ({ buttonProps, imageUrl, title, description }: TutorialCardProps) => {
  return (
    <Card
      elevation={0}
      variant="outlined"
      sx={{
        borderRadius: 4,
      }}
    >
      <CardMedia sx={{ height: 240 }} image={imageUrl} title={title} />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
        <TutorialButton {...buttonProps} />
      </CardContent>
    </Card>
  );
};
