"use client";
import { useOrganization } from "@clerk/nextjs";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { ArrowRight } from "@untitled-ui/icons-react";
import Image from "next/image";
import Link from "next/link";
import { useFeatureFlags } from "~/app/hooks/useFeatureFlags";
import { UpgradeButton } from "~/components/upgrade/button";

export const CallToActionItems = () => {
  const { organization } = useOrganization();
  const socialsEnabled = useFeatureFlags("social_signal");
  const crmSyncEnabled = useFeatureFlags("crm_syncs");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ maxWidth: 1200 }}>
        <Grid
          spacing={2}
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Card
                variant="outlined"
                elevation={0}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 2,
                  minHeight: 450,
                  textAlign: "center",
                  p: 3,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    minHeight: 300,
                    borderRadius: 3,
                    overflow: "hidden",
                    position: "relative",
                    backgroundColor: (theme) => theme.palette.background.default,
                    p: (theme) => `${theme.spacing(3)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
                  }}
                >
                  <Image src="/assets/Signals.svg" alt="trigify-socail-search" fill style={{ top: 20 }} />
                </Box>

                <Box>
                  <Typography variant="h5">Social signals</Typography>
                  <Typography variant="body2">
                    Set up Trigify to listen for social signals and stay on top of all your leads.
                  </Typography>
                </Box>
                {socialsEnabled ? (
                  <Link href="/social/setup" passHref>
                    <Button variant="contained" endIcon={<ArrowRight />}>
                      Start setup
                    </Button>
                  </Link>
                ) : (
                  <UpgradeButton />
                )}
              </Card>
            </Grid>

            <Grid item md={6} xs={12}>
              <Card
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 2,
                  minHeight: 450,
                  textAlign: "center",
                  p: 3,
                }}
                variant="outlined"
                elevation={0}
              >
                <Box
                  sx={{
                    width: "100%",
                    minHeight: 300,
                    borderRadius: 3,
                    overflow: "hidden",
                    position: "relative",
                    backgroundColor: (theme) => theme.palette.background.default,
                    p: (theme) => `${theme.spacing(3)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
                  }}
                >
                  <Image src="/assets/prospecting.svg" alt="trigify-prospecting" fill style={{ top: 20 }} />
                </Box>
                <Box>
                  <Typography variant="h5">Signal prospecting</Typography>
                  <Typography variant="body2">Leverage signals to find prospects.</Typography>
                </Box>
                <Link href={`/lists/${organization?.id!}?modalState=open`} passHref>
                  <Button variant="contained" endIcon={<ArrowRight />}>
                    Begin prospecting
                  </Button>
                </Link>
              </Card>
            </Grid>

            <Grid item md={6} xs={12}>
              <Card
                variant="outlined"
                elevation={0}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 2,
                  minHeight: 450,
                  textAlign: "center",
                  p: 3,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    minHeight: 300,
                    borderRadius: 3,
                    overflow: "hidden",
                    position: "relative",
                    backgroundColor: (theme) => theme.palette.background.default,
                    p: (theme) => `${theme.spacing(3)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
                  }}
                >
                  <Image src="/assets/hubspot-hero.svg" alt="trigify-person-search" fill style={{ top: 20 }} />
                </Box>

                <Box>
                  <Typography variant="h5">Connect your accounts</Typography>
                  <Typography variant="body2">
                    Connect your favourite accounts and leverage all your contact data in one place.
                  </Typography>
                </Box>
                {crmSyncEnabled ? (
                  <Link
                    href={organization?.id ? `/connect/${organization?.id}/accounts` : `/connect/create-organisation`}
                    passHref
                  >
                    <Button variant="contained" endIcon={<ArrowRight />}>
                      Connect
                    </Button>
                  </Link>
                ) : (
                  <UpgradeButton />
                )}
              </Card>
            </Grid>

            <Grid item md={6} xs={12}>
              <Card
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 2,
                  minHeight: 450,
                  textAlign: "center",
                  p: 3,
                }}
                variant="outlined"
                elevation={0}
              >
                <Box
                  sx={{
                    width: "100%",
                    minHeight: 300,
                    borderRadius: 3,
                    overflow: "hidden",
                    position: "relative",
                    backgroundColor: (theme) => theme.palette.background.default,
                    p: (theme) => `${theme.spacing(3)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
                  }}
                >
                  <Image src="/assets/smart-tags.svg" alt="trigify-smart-tags" fill style={{ top: 20 }} />
                </Box>
                <Box>
                  <Typography variant="h5">AI Smart Tags</Typography>
                  <Typography variant="body2">
                    {" "}
                    AI Smart Tags are a way to automatically categorize your leads. You can use them to qualify new
                    leads in to your ICP.
                  </Typography>
                </Box>
                <Link href="/settings/smart-tags" passHref>
                  <Button variant="contained" endIcon={<ArrowRight />}>
                    Create AI Smart Tags
                  </Button>
                </Link>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
