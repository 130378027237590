"use client";

import { Avatar, Box, Button, Card, Typography } from "@mui/material";
import { UserSubscription } from "@prisma/client";
import { Briefcase01, Edit05, Mail01, MarkerPin01 } from "@untitled-ui/icons-react";
import Link from "next/link";
import { GettingStartedWidget } from "~/_pages/dashboard/v2/getting-started";
import { useGetUserPlan } from "~/app/hooks/useGetUserPlan";
import { SmallChip } from "../inputs/free-solo-autocomplete";
import { TutorialCard } from "./tutorial";

const generateUIFriendlyPlanName = (sub: UserSubscription) => {
  switch (sub) {
    case UserSubscription.SOCIAL_SIGNAL:
      return "Social Signal";
    case UserSubscription.CUSTOM:
      return "Custom";
    case UserSubscription.ENTERPRISE:
      return "Enterprise";
    case UserSubscription.SENIOR:
    case UserSubscription.GROWTH:
      return "Growth";
    case UserSubscription.SENIOR_PLUS:
    case UserSubscription.SCALE:
      return "Scale";
    case UserSubscription.JUNIOR:
    case UserSubscription.JUNIOR_PLUS:
      return "Solo";
    default:
      return "Free";
  }
};

/**
 * ProfileCard component displays the user's profile information.
 * It includes the user's name, avatar, email address, role, and country.
 * The component also provides an option to edit the user's profile.
 *
 * @returns The rendered ProfileCard component.
 */
export const ProfileCard = () => {
  const { userPlan, user } = useGetUserPlan();
  const name = user?.firstName ? user?.firstName : "";

  return (
    <Box
      sx={(theme) => ({
        gap: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
      })}
    >
      <Card
        elevation={0}
        variant="outlined"
        sx={{
          position: "relative",
          // height: "97vh",
          borderRadius: 4,
        }}
      >
        <Box
          sx={(theme) => ({
            background: theme.gradient,
            width: "100%",
            height: 120,
          })}
        />
        <Avatar
          sx={{
            position: "absolute",
            top: 90,
            left: 20,
            width: 80,
            height: 80,
            zIndex: 2,
            border: "4px solid #fff",
          }}
          src={user?.imageUrl}
        />
        <Box
          sx={(theme) => ({
            marginTop: theme.spacing(6),
            padding: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
            justifyContent: "center",
          })}
        >
          <Typography variant="h5">
            {name}
            <SmallChip
              sx={{
                ml: 2,
              }}
              variant="outlined"
              label={generateUIFriendlyPlanName(userPlan?.subscription_plan!)}
            />
          </Typography>
          <Typography
            variant="body1"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            })}
          >
            <Mail01
              style={{
                width: 20,
                height: 20,
              }}
            />
            {user?.emailAddresses[0]?.emailAddress}
          </Typography>
          <Typography
            variant="body1"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            })}
          >
            <Briefcase01
              style={{
                width: 20,
                height: 20,
              }}
            />
            {(user?.publicMetadata?.role as string) ?? "--"}
          </Typography>
          <Typography
            variant="body1"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            })}
          >
            <MarkerPin01
              style={{
                width: 20,
                height: 20,
              }}
            />
            {(user?.publicMetadata?.country as string) ?? "--"}
          </Typography>
          <Link passHref href="/settings">
            <Button variant="text" startIcon={<Edit05 />}>
              Edit Profile
            </Button>
          </Link>
        </Box>
      </Card>
      <GettingStartedWidget />

      <TutorialCard
        buttonProps={{
          videoUrl: "https://youtu.be/E85tv0WorbA?si=wxRe-3Lk45u0DRRj",
          modalTitle: "Full product tutorial",
        }}
        imageUrl="/hero/product-tour-v2.jpg"
        title="Learn how to use Trigify"
        description="Discover the full range of features Trigify has to offer."
      />
    </Box>
  );
};
