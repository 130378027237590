import type { ButtonProps } from "@mui/material";
import { Button } from "@mui/material";
import { Rocket02 } from "@untitled-ui/icons-react";
import Link from "next/link";

export const UpgradeButton = ({ title, ...others }: { title?: string } & ButtonProps) => {
  return (
    <Link passHref href="/settings/manage-plan">
      <Button
        {...others}
        sx={(theme) => ({
          fontSize: "14px",
          fontWeight: 600,
          height: "50",
          minWidth: 175,
          border: (theme) => `1px solid ${theme.palette.primary.main}`,
          backgroundImage: `${theme.gradient} !important`,
        })}
        endIcon={<Rocket02 />}
        variant="contained"
        id="upgrade"
      >
        {title ?? "Upgrade now"}
      </Button>
    </Link>
  );
};
