"use client";
import type { TextFieldProps } from "@mui/material";
import { Autocomplete, Chip, TextField, createFilterOptions } from "@mui/material";
import { styled } from "@mui/material/styles";
import { XClose } from "@untitled-ui/icons-react";
import { capitalize, isEqual } from "lodash";
import type { ChangeEvent } from "react";
import { useEffect, useState } from "react";
import { ControlCenterConfig } from "../forms/control-center/config";
import { ButtonSpinner } from "../loaders/button-spinner";

export type OptionType<TTitle extends string = string, TId extends string | number = string | number> = {
  title: TTitle;
  id?: TId;
  inputValue?: TTitle;
};

type ChipTextFieldProps = Omit<TextFieldProps, "onChange" | "value"> & {
  options: OptionType[];
  onChange: (value: OptionType[]) => void;
  value: OptionType[];
  disableCloseOnSelect?: boolean;
  freeSolo?: boolean;
  maxItems?: number;
  onInputChange?: (value: string) => void;
  async?: boolean;
  loading?: boolean;
  errorText?: string;
};

export const filter = createFilterOptions<OptionType>();

export const SmallChip = styled(Chip)(({ theme }) => ({
  "& .MuiChip-root": {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.sizes.borderRadius.small,
    color: theme.palette.text.primary,
    height: "24px",
  },
  "& .MuiChip-deleteIcon": {
    width: "14px",
  },
}));

export default function ChipTextField({
  label,
  id,
  disableCloseOnSelect = false,
  options,
  value,
  disabled,
  freeSolo = false,
  onChange,
  loading,
  maxItems = ControlCenterConfig.MAX_ITEMS,
  onInputChange,
  async = false,
  errorText = "",
  ...others
}: ChipTextFieldProps) {
  const [values, setValue] = useState<OptionType[]>(value);
  const handleChange = (_: React.SyntheticEvent, newValue: (OptionType | string)[]) => {
    if (newValue.length > maxItems) return;

    setValue(newValue.map((item) => (typeof item === "string" ? { title: item, inputValue: item } : item)));
    onChange(newValue.map((item) => (typeof item === "string" ? { title: item, inputValue: item } : item)));
  };

  useEffect(() => {
    if (!isEqual(values, value)) setValue(value);
  }, [value, values]);

  const itemsExceeded = values.length >= maxItems;

  const helperText = values.length >= maxItems ? "Maximum options selected" : errorText;

  const isDisabled = disabled || values.length >= maxItems;

  return (
    <Autocomplete
      multiple
      limitTags={maxItems}
      value={values}
      onChange={handleChange}
      filterOptions={
        async
          ? (x) => x
          : (options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue.toLocaleLowerCase() === option.title.toLocaleLowerCase(),
              );

              if (freeSolo && inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  title: inputValue,
                });
              }

              return filtered;
            }
      }
      disabled={disabled}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      ChipProps={{
        deleteIcon: <XClose />,
      }}
      sx={(theme) => ({
        "& .MuiInputBase-root .MuiChip-root": {
          background: theme.palette.background.paper,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: theme.sizes.borderRadius.medium,
          color: theme.palette.text.primary,
          height: "24px",
        },
        "& .MuiInputBase-root .MuiChip-deleteIcon": {
          width: "14px",
        },
        "& .MuiInputBase-root": {
          borderRadius: theme.sizes.borderRadius.medium,
          backgroundColor: theme.palette.background.paper,
        },
        "& .Mui-focused": {
          borderRadius: theme.sizes.borderRadius.medium,
        },
      })}
      id={id}
      options={options}
      disableCloseOnSelect={disableCloseOnSelect}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(props, option) => <li {...props}>{capitalize(option.title)}</li>}
      freeSolo={freeSolo}
      fullWidth
      isOptionEqualToValue={(option, value) => option.title === value.title}
      renderInput={(params) => (
        <TextField
          {...params}
          {...others}
          label={label}
          disabled={isDisabled}
          InputProps={{
            ...params.InputProps,
            endAdornment: loading ? <ButtonSpinner /> : params.InputProps?.endAdornment,
          }}
          helperText={helperText}
          error={!!helperText}
          inputProps={{
            ...params.inputProps,
            style: {
              width: "100%",
            },
            onChange: (e) => {
              const evt = e as ChangeEvent<HTMLInputElement>;
              if (itemsExceeded) return;
              if (onInputChange) onInputChange(evt.target.value);
              params?.inputProps?.onChange?.(evt);
            },
          }}
        />
      )}
    />
  );
}
